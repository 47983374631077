import React from 'react'
import Button from 'antd/es/button'
import classNames from 'classnames'

const handleOnClick = () => {
  if (window.dataLayer) window.dataLayer.push({ event: 'meetingRequested' })
}

export default function AskDemoButton ({ type, label, className }) {
  return (
    <a onClick={handleOnClick} href="/reserver-une-demo/" rel="noopener noreferrer">
      <Button size='large' className={classNames('rc-cta', className)} type={type || 'normal'}>{label || 'Réserver une démo'}</Button>
    </a>
  )
}
