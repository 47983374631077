import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, Typography, Button } from 'antd'

import Layout from '../components/layout'
import Head from '../components/head'
import BetaTestersLogos from '../components/beta-testers-logos'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import CtaRow from '../components/cta-row'
import Testimonials from '../components/testimonials'
import StoreReviews from '../components/store-reviews'

const { Title, Paragraph, Text } = Typography

const AgicapAlternatives = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "rocketchart-dashboard-v2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      comparaisonTarifsImage: file(relativePath: { eq: "comparaison-tarifs-agicap-rocketchart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      interfaceImage: file(relativePath: { eq: "rocketchart-overview-dashboard-v2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rapideImage: file(relativePath: { eq: "rocketchart-budget-previsionnel.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      feedbackImage: file(relativePath: { eq: "agicap-rocketchart-feedback.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
      agicapLogoImage: file(relativePath: { eq: "logo-agicap.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rocketchartLogoImage: file(relativePath: { eq: "logo-rocketchart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const description = 'Découvrez RocketChart, la nouvelle alternative à Agicap pour gérer et anticiper facilement votre trésorerie.'

  return (
    <Layout>
      <Head
        title="Notre comparatif entre RocketChart et Agicap"
        description={description}
        canonical="https://rocketchart.co/comparatif-rocketchart-vs-agicap/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={24} lg={11}>
          <Title>Notre comparatif entre RocketChart et Agicap</Title>
          <Paragraph style={{ color: 'black' }}>Vous recherchez une alternative au logiciel de trésorerie Agicap ? Quel est le meilleur outil de gestion de trésorerie ? Découvrez RocketChart, la nouvelle solution française pour gérer et anticiper facilement votre trésorerie.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Gagnez du temps avec un suivi automatisé en temps réel et pilotez sereinement votre entreprise avec des prévisions fiables.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Voici notre comparaison entre RocketChart et Agicap.</Paragraph>

          <CtaRow />
        </Col>
        <Col xs={24} lg={13} justify="center">
          <GatsbyImage image={data.mainImage.childImageSharp.gatsbyImageData} alt="RocketChart, logiciel de gestion et prévision de trésorerie" className="rounded-image rc-basic-shadow" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" type="flex" justify="center" style={{ marginTop: '30px', marginBottom: '20px' }}>
        <Col xs={24} lg={20} align='center'>
          <div className='w-full max-w-md lg:max-w-none'>
            <Text strong style={{ color: '#6a737d' }}>Ils ont préféré RocketChart pour gérer leur trésorerie</Text>
            <BetaTestersLogos />
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center">
        <Col xs={{ span: 24 }} lg={{ span: 18 }} align='center'>
          <Title level={2}>Vous cherchez une alternative à Agicap ?</Title>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 14 }} style={{ marginTop: '50px' }}>
          <Paragraph style={{ color: 'black' }}>Les fondateurs d’Agicap ont été précurseurs en France lorsqu’ils ont lancé leur outil de gestion de trésorerie en 2016. Ils ont apporté une innovation - déjà existante en Angleterre depuis 2012 avec Float App - pour aider les dirigeants et directeurs financiers français à mieux suivre leur trésorerie.</Paragraph>
          <Paragraph style={{ color: 'black' }}>RocketChart a été lancé en 2020 pour aider les entreprises à gérer facilement leur trésorerie. Nous souhaitons proposer une solution alternative pour les dirigeants et directeurs financiers de TPE et PME en France. Notamment avec une tarification adaptée, une relation de proximité avec nos clients adorés et une écoute particulière des retours utilisateurs pour l'amélioration continue du produit. Nous sommes indépendants - sur fonds propres - 100% financé par nos clients depuis le départ.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Si vous cherchez à remplacer Agicap ou comparer les solutions, voici quelques raisons qui vous feront sérieusement considérer RocketChart.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={10} justify="center">
          <Paragraph align='center'>
            <GatsbyImage image={data.comparaisonTarifsImage.childImageSharp.gatsbyImageData} style={{ maxWidth: 300 }} alt="Tarifs Agicap RocketChart" />
          </Paragraph>
        </Col>
        <Col xs={24} lg={14}>
          <Title level={2}>Vous recherchez une solution abordable ?</Title>
          <Paragraph style={{ color: 'black' }}>Pourquoi notre prix d'appel est de 79€ /mois ou 790€ /an ?</Paragraph>
          <Paragraph style={{ color: 'black' }}>L'effectif de RocketChart est réduit, nous n'avons pas de force commerciale, et nous avons opté pour une entreprise en "remote" (télétravail complet). Tout cela permet de limiter nos frais de structure pour proposer un tarif abordable pour les dirigeants de TPE et PME. Nos offres démarrent à partir de 79€ /mois pour la formule mensuelle, et vous bénéficiez de <Text strong>2 mois offerts</Text> si vous optez pour la formule annuelle (790€ /an = 65€ /mois).</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center">
        <Col xs={{ span: 24 }} lg={{ span: 18 }} align='center' style={{ marginBottom: '40px' }}>
          <Title level={2}>L’offre transparente de RocketChart</Title>
        </Col>
        <Col xs={24} lg={8}>
          <Title level={3}>Tarifs clairs</Title>
          <Paragraph>Pourquoi cacher nos prix ? Ils sont accessibles sur notre site et aucune offre ne varie : pas de tarif à la tête du client. La <Text strong>transparence</Text> est le début d’une bonne relation.</Paragraph>
        </Col>
        <Col xs={24} lg={8}>
          <Title level={3}>Essai gratuit</Title>
          <Paragraph>Vous avez besoin d’essayer une solution avant d’acheter, pour vérifier qu’elle répond à vos besoins. C’est pour ça que nous vous offrons toujours <Text strong>15 jours d’essai gratuit</Text>.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Une entreprise humaine, qui écoute vos feedbacks.</Title>
          <Paragraph style={{ color: 'black' }}>Nous n’avons pas des milliers de clients. Des centaines nous suffisent amplement. Chacun d’eux est unique. Chacun d’eux est écouté. Nous connaissons le nom et prénom de chacun d’eux. Car nous créons cet outil pour eux, pour vous.</Paragraph>
          <Paragraph style={{ color: 'black' }}>C’est grâce aux feedbacks de nos clients adorés que nous améliorons RocketChart au quotidien et qu’il deviendra le meilleur logiciel de trésorerie. Vous n’êtes pas juste un numéro quand vous appelez. Vous n’êtes pas juste un identifiant sur notre Live Chat. Vous êtes Laurence, Maxime, Lionel, Aurélien, Benoît, Florent, Michael, Pierre, ...</Paragraph>
          <Paragraph style={{ color: 'black' }}>Et c’est grâce à vous, et uniquement vous, que nous pouvons vivre de notre activité aujourd’hui. Et la moindre des choses est de vous écouter.</Paragraph>
        </Col>
        <Col xs={24} lg={12} justify="center">
          <GatsbyImage image={data.feedbackImage.childImageSharp.gatsbyImageData} alt="Importance feedbacks RocketChart" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }} justify="center">
          <GatsbyImage image={data.interfaceImage.childImageSharp.gatsbyImageData} alt="Interface Agicap RocketChart" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Une interface claire et visuelle, qui ne ressemble pas à votre tableau Excel.</Title>
          <Paragraph style={{ color: 'black' }}>Par le passé, vous avez certainement utilisé Excel - ou Google Sheet - pour gérer et projeter votre trésorerie. Ces outils sont très flexibles et personnalisables. Mais leurs interfaces utilisateur manquent de lisibilité et de clarté. En plus d’être laborieux à utiliser et à mettre à jour. Vous ne choisissez certainement pas un logiciel de trésorerie pour retrouver ce sentiment.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Chez RocketChart, notre logiciel ne camoufle pas un semblant d’Excel. Nous avons pensé vos dashboards et l’ensemble des interfaces pour que vous preniez plaisir à suivre votre trésorerie.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Vous gagnez du temps avec un suivi automatisé, vous anticipez votre trésorerie avec une interface claire, tout en utilisant un outil agréable tous les jours.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Une solution simple et intuitive, pour vous faire gagner du temps.</Title>
          <Paragraph style={{ color: 'black' }}>Votre temps est précieux. En tant que dirigeant ou DAF vous jonglez constamment entre différents sujets. Vous devez être efficace pour dynamiter votre ToDo et développer votre activité. Nous vivons exactement la même chose avec le développement de notre entreprise RocketChart donc nous connaissons vos frustrations.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Depuis le début, notre objectif est de vous faire gagner du temps. Par exemple avec une gestion de trésorerie automatisée en temps réel. Mais nous voulons aller plus loin. C’est pourquoi nous optimisons constamment les performances techniques de notre solution. Afin qu’elle réponde toujours plus vite à vos clics, qu’elle affiche vos données en un battement de cils et que vous trouviez l’information d’un seul coup d’oeil.</Paragraph>
        </Col>
        <Col xs={24} lg={12} justify="center">
          <GatsbyImage image={data.rapideImage.childImageSharp.gatsbyImageData} alt="Rapide RocketChart" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center" style={{ marginBottom: '60px' }}>
        <Col xs={{ span: 24 }} align='center'>
          <Title level={2}>Comparez Agicap et RocketChart</Title>
        </Col>
      </Row>

      <Row className="content-container" gutter={[40, 20]} style={{ fontSize: 14 }}>
        <Col xs={{ offset: 12, span: 6 }} align='center'>
          <GatsbyImage image={data.agicapLogoImage.childImageSharp.gatsbyImageData} alt="logo Agicap" style={{ maxHeight: 30 }} imgStyle={{ objectFit: 'contain' }} />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <GatsbyImage image={data.rocketchartLogoImage.childImageSharp.gatsbyImageData} alt="logo RocketChart" style={{ maxHeight: 30 }} imgStyle={{ objectFit: 'contain' }} />
        </Col>

        <Col xs={{ span: 12 }}>
          Synchronisation multi-comptes bancaires
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Catégorisation automatique des opérations
          </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Budgets prévisionnels
          </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Comparaison Réalisé vs Budgets prévisionnels
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Trésorerie prévisionnelle
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Multi-scénarios prévisionnels
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Calculs de TVA automatisé
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Export et reporting
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Intégration directe avec vos outils de gestion
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Indicateurs clés et formules sur mesure
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CloseOutlined className='rc-large-text rc-red-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Graphiques sur mesure
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CloseOutlined className='rc-large-text rc-red-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Vision holding / multi-entités
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CloseOutlined className='rc-large-text rc-red-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Multi-devises
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CloseOutlined className='rc-large-text rc-red-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Multi-langues
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CloseOutlined className='rc-large-text rc-red-text' />
        </Col>

        <br />
        <br />
        <br />
        <br />

        <Col xs={{ span: 12 }}>
          Support (live chat et email)
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }}>
          Période d’essai gratuit
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>
        <Col xs={{ span: 6 }} align='center'>
          <CheckOutlined className='rc-large-text rc-green-text' />
        </Col>

        <Col xs={{ span: 12 }} >
          Prix d'appel
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text'>
          Non-visible sur le site
        </Col>
        <Col xs={{ span: 6 }} align='center' className='rc-bold-text'>
          79€ /mois
        </Col>
      </Row>

      <br />
      <br />

      <iframe loading="lazy" width="560" height="315" className='max-w-full' title="Merci d'avoir lu le comparatif." src="https://www.youtube.com/embed/evLhCnUfjYE?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <Row className="content-container space-around-row" type="flex" justify="center">
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>
            Ils en parlent mieux que nous
          </Title>
        </Col>
      </Row>

      <Testimonials />

      <Row className="content-container space-around-row" type="flex" justify="center">
        <Col xs={24} lg={20} align='center' style={{ marginTop: '0px', marginBottom: '20px' }}>
          <div className='w-full max-w-md lg:max-w-none'>
            <BetaTestersLogos />
          </div>
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '100px', marginBottom: '0px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col span={24} align='center' className='mt-5 mb-24 flex flex-col items-center'>
          <Title level={2} className='mb-8'>Démarrez maintenant votre essai gratuit</Title>
            <CtaRow />
          <div className="mt-4">
            <StoreReviews />
          </div>
        </Col>
      </Row>

    </Layout>
  )
}

export default AgicapAlternatives
