import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col } from "antd"

const BetaTestersLogos = () => {
  const data = useStaticQuery(graphql`
    query {
      pinpoImage: file(relativePath: { eq: "pinpo-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      champercheImage: file(relativePath: { eq: "champerche-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      hubvisoryImage: file(relativePath: { eq: "hubvisory-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      meilleursBiensImage: file(relativePath: { eq: "meilleursbiens-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      studiomaticImage: file(relativePath: { eq: "studiomatic-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      welybImage: file(relativePath: { eq: "welyb-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      gutter={{ xs: 20, sm: 20, lg: 40 }}
      className="w-full "
    >
      <Col xs={8} lg={4} align="center" className='mt-2 md:mt-4 filter grayscale hover:grayscale-0'>
        <GatsbyImage loading='eager' image={data.pinpoImage.childImageSharp.gatsbyImageData} alt="Pinpo logo" />
      </Col>
      <Col xs={8} lg={4} align="center" className='mt-2 md:mt-4 filter grayscale hover:grayscale-0'>
        <GatsbyImage loading='eager' image={data.champercheImage.childImageSharp.gatsbyImageData} alt="Champerché logo" />
      </Col>
      <Col xs={8} lg={4} align="center" className='mt-2 md:mt-4 filter grayscale hover:grayscale-0'>
        <a href="https://www.welyb.fr"><GatsbyImage loading='eager' image={data.welybImage.childImageSharp.gatsbyImageData} alt="Welyb logo" /></a>
      </Col>
      <Col xs={8} lg={4} align="center" className='mt-2 md:mt-4 filter grayscale hover:grayscale-0'>
        <a href="https://hubvisory.com"><GatsbyImage loading='eager' image={data.hubvisoryImage.childImageSharp.gatsbyImageData} alt="Hubvisory logo" /></a>
      </Col>
      <Col xs={8} lg={4} align="center" className='mt-2 md:mt-4 filter grayscale hover:grayscale-0'>
        <GatsbyImage loading='eager' image={data.meilleursBiensImage.childImageSharp.gatsbyImageData} alt="MeilleursBiens logo" />
      </Col>
      <Col xs={8} lg={4} align="center" className='mt-2 md:mt-4 filter grayscale hover:grayscale-0'>
        <GatsbyImage loading='eager' image={data.studiomaticImage.childImageSharp.gatsbyImageData} alt="Studiomatic logo" />
      </Col>
    </Row>
  )
}

export default BetaTestersLogos