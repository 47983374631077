import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import cx from 'classnames'

function Testimonial({ picture, logo, name, role, quote, className }) {
  return (
    <div className={cx('flex flex-col shadow-xl rounded-lg p-8 items-center bg-white border-t-4 border-primary', className)} >
      <GatsbyImage
        image={picture.childImageSharp.gatsbyImageData}
        className='h-28 w-28'
        alt={name}
      />

      <div className='relative text-lg mt-4'>
        <svg
          className="absolute top-0 left-0 transform -translate-x-4 -translate-y-8 h-16 w-16 text-light-primary opacity-50"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 144 144"
          aria-hidden="true"
        >
          <path
            strokeWidth={2}
            d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
          />
        </svg>
        {quote}
      </div>

      <div className='mt-4 font-bold'>{name}</div>
      <div>{role}</div>
    </div>
  )
}

export default function Testimonials() {
  const data = useStaticQuery(graphql`
    query {
      lionelImage: file(relativePath: { eq: "lionel-hovsepian.png" }) {
        childImageSharp {
          gatsbyImageData(width: 350)
        }
      }
      benoitImage: file(relativePath: { eq: "benoit-maury.png" }) {
        childImageSharp {
          gatsbyImageData(width: 350)
        }
      }
      michaelMichettiImage: file(relativePath: { eq: "michael-michetti.png" }) {
        childImageSharp {
          gatsbyImageData(width: 350)
        }
      }
      pierreImage: file(relativePath: { eq: "pierre-cathelin.png" }) {
        childImageSharp {
          gatsbyImageData(width: 350)
        }
      }
      michaelImage: file(relativePath: { eq: "michael-benchabat-meilleursbiens.png" }) {
        childImageSharp {
          gatsbyImageData(width: 350)
        }
      }
      aurelienImage: file(relativePath: { eq: "aurelien-lovalente.png" }) {
        childImageSharp {
          gatsbyImageData(width: 350)
        }
      }
      hubvisoryImage: file(relativePath: { eq: "hubvisory-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      meilleursBiensImage: file(relativePath: { eq: "meilleursbiens-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      studiomaticImage: file(relativePath: { eq: "studiomatic-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
    }
  `)

  return (
    <div className='content-container space-around-row flex flex-col items-center'>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-12'>
        <Testimonial
          picture={data.lionelImage}
          logo={data.wcsImage}
          name='Lionel Hovsepian'
          role='Président - WCS Europe'
          quote="Je maîtrise enfin l'évolution de ma trésorerie au doigt et à l’oeil grâce à Rocketchart. Chaque mouvement est auto-catégorisé, je gère mon cash-flow en temps réel et réalise mes prévisions en ajoutant des simulations. Chez WCS Europe, nous appelons ça : l’innovation digitale au coeur de la performance."
          className='transform -rotate-1 md:-rotate-2'
        />

        <Testimonial
          picture={data.benoitImage}
          logo={data.welybImage}
          name='Benoît Maury'
          role='Co-fondateur & CEO - Welyb'
          quote="RocketChart est un outil hyper intuitif me permettant de suivre ma tréso en temps réel. L'affectation des opérations est très simple et sa fonctionnalité d'export des données me permet de mettre à jour très facilement mon business plan sur Google Sheet. J'adore !"
          className='transform rotate-1 md:rotate-2'
        />

        <Testimonial
          picture={data.michaelMichettiImage}
          logo={data.emSolarImage}
          name='Michael Michetti'
          role='Président - EMSolar'
          quote="Ne cherchez plus, ils l'ont fait - simplement BRAVO !! Fini Excel et fini le temps infini à passer pour gérer sa trésorerie et ses prévisions. RocketChart est très agréable, facile d'utilisation et comporte toutes les fonctionnalités attendues pour une bonne gestion de trésorerie."
          className='transform -rotate-2 md:-rotate-3'
        />

        <Testimonial
          picture={data.pierreImage}
          logo={data.hubvisoryImage}
          name='Pierre Cathelin'
          role='Co-fondateur - Hubvisory'
          quote="RocketChart est un outil efficace pour suivre sa trésorerie. Il s'adapte bien à notre business model grâce aux catégories personnalisées et nous avons une bonne vue sur la performance de la société. Les scénarios nous permettent d'anticiper les impacts de nos choix pour prendre les bonnes décisions."
          className='transform rotate-2 md:rotate-1'
        />

        <Testimonial
          picture={data.michaelImage}
          logo={data.meilleursBiensImage}
          name='Michael Benchabat'
          role='PDG - MeilleursBiens.com'
          quote="Après avoir testé plusieurs solutions, j'utilise RocketChart depuis quelques mois. J'ai enfin de la visibilité sur ma tréso et ma TVA. Outil très complet, à l'ergonomie simple et intuitive. L'équipe est à l'écoute pour faire évoluer le produit. Le support est toujours présent en cas de besoin."
          className='transform -rotate-1 md:-rotate-1'
        />

        <Testimonial
          picture={data.aurelienImage}
          logo={data.studiomaticImage}
          name='Aurelien Lovalente'
          role='Co-fondateur - Studiomatic'
          quote="RocketChart me permet d’avoir une vision instantanée sur l’état de ma trésorerie, et m’aide à prendre rapidement les bonnes décisions grâce aux scénarios prévisionnels de trésorerie : lorsqu’on doit arbitrer un investissement ou une dépense particulière. Le gain de temps est inestimable !"
          className='transform rotate-1 md:rotate-2'
        />
      </div>
    </div>
  )
}

