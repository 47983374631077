import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { StarIcon } from "@heroicons/react/solid"

const StoreReviews = () => {
  const data = useStaticQuery(graphql`
    query {
      appvizerImage: file(relativePath: { eq: "appvizer-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      cciStoreImage: file(relativePath: { eq: "cci-store-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className="flex flex-row items-center lg:items-start space-x-16">
      <div className='w-28 flex flex-col'>
        <div className='w-full flex flex-row justify-between'>
          <StarIcon className='w-6 h-6 text-yellow-500' />
          <StarIcon className='w-6 h-6 text-yellow-500' />
          <StarIcon className='w-6 h-6 text-yellow-500' />
          <StarIcon className='w-6 h-6 text-yellow-500' />
          <StarIcon className='w-6 h-6 text-yellow-500' />
        </div>

        <GatsbyImage image={data.appvizerImage.childImageSharp.gatsbyImageData} alt="Appvizer" />
      </div>

      <div className='w-28 flex flex-col'>
        <div className='w-full flex flex-row justify-between'>
          <StarIcon className='w-6 h-6 text-yellow-500' />
          <StarIcon className='w-6 h-6 text-yellow-500' />
          <StarIcon className='w-6 h-6 text-yellow-500' />
          <StarIcon className='w-6 h-6 text-yellow-500' />
          <StarIcon className='w-6 h-6 text-yellow-500' />
        </div>

        <GatsbyImage image={data.cciStoreImage.childImageSharp.gatsbyImageData} alt="CCI Store" />
      </div>
    </div>
  )
}

export default StoreReviews