import React from 'react'
import FreeTrialCta from './free-trial-cta'
import AskDemoButton from './ask-demo-button'

export default function CtaRow () {
  return (
    <div className='rc-cta-row'>
      <div><FreeTrialCta /></div>

      <AskDemoButton />
    </div>
  )
}
